import contractIcon from '@assets/svg/picto-contract.svg'
import legalIcon from '@assets/svg/picto-legal-constraints.svg'
import paymentIcon from '@assets/svg/picto-payment.svg'
import planningIcon from '@assets/svg/picto-planning.svg'
import replacementIcon from '@assets/svg/picto-replacement.svg'
import transfertIcon from '@assets/svg/picto-transfert.svg'

const functionalities = [
    {
        id: 1,
        media: planningIcon,
        title: 'Création des plannings en 2 heures',
        content:
            'Notre interface ergonomique vous permet de réduire drastiquement le temps de préparation des trames de planning et des roulements.',
    },
    {
        id: 2,
        media: legalIcon,
        title: 'Intégration de vos contraintes réglementaires',
        content:
            "Notre solution de planning pour EHPAD vous fournit un décompte précis des heures des remplaçants et s'occupe de vos déclarations préalables à l'embauche (DPAE).",
    },
    {
        id: 3,
        media: paymentIcon,
        title: 'Transmission des éléments de pré-paie à votre logiciel',
        content:
            'Connecté à votre logiciel de paie, notre outil de gestion de planning pour EHPAD calcule et transmet automatiquement les éléments variables de paie, sans aucune ressaisie.',
    },
    {
        id: 4,
        media: replacementIcon,
        title: 'Traitement des demandes de remplacement en 5 minutes',
        content:
            "Notre application mobile permet à vos collaborateurs d'indiquer leurs disponibilités et d'être alertés en temps réel en cas de postes vacants à pourvoir immédiatement.",
    },
    {
        id: 5,
        media: transfertIcon,
        title: "Bourse d'échanges d'horaires entre collaborateurs",
        content:
            "Via notre application mobile, vos collaborateurs peuvent échanger des jours, des horaires. Vous recevez les demandes d'échange et les validez en 1 clic.",
    },
    {
        id: 6,
        media: contractIcon,
        title: 'Génération automatique des contrats de travail',
        content:
            'Notre solution intègre vos modèles de contrats de travail et les génère automatiquement. Grâce à la signature électronique, toutes vos embauches peuvent être gérées à distance.',
    },
]

export default functionalities
