const features = [
    'Création des trames de plannings et des roulements des équipes en 2h',
    'Recherche automatique des remplaçants basée sur les disponibilités des salariés',
    "Notifications d'absences ou de modification de planning en temps réel",
    'Suivi statistique des temps de présence pour générer la prépaie',
    'Génération des contrats et signature électronique',
    'Contrôle automatique de la conformité des plannings',
]

export default features
