import React from 'react'

import { Flex, Text, useMediaQuery } from '@chakra-ui/react'
import { device } from '@helpers/mediaQueryBreakpoints'

interface QuoteProps {
    nbCol?: 1 | 2 | 3
    content: string
    author: string
}

const Quote = ({ nbCol = 1, content, author }: QuoteProps) => {
    const [isMobile] = useMediaQuery(device.xs)

    return (
        <Flex direction="column" gap="3">
            <Text fontSize="xs" color="indigo.500" style={{ columnCount: isMobile ? 1 : nbCol, columnGap: '30px' }}>
                {content}
            </Text>

            <Text fontSize="xs" fontWeight="bold" color="indigo.500" align="end">
                {author}
            </Text>
        </Flex>
    )
}
export default Quote
