import imgMobileAvailabilities from '@assets/images/mobile-availabilities.png'
import imgMobileExchange from '@assets/images/mobile-exchange.png'
import imgMobileNotifications from '@assets/images/mobile-notifications.png'
import imgMobilePlanning from '@assets/images/mobile-planning.png'

const content = [
    {
        title: 'Accès et consultation de son propre planning',
        description: "N'importe où et n'importe quand, depuis l'application mobile Staffea.",
        image: imgMobilePlanning,
        alt: 'Staffea, application mobile consultation du planning',
    },
    {
        title: 'Partage de ses disponibilités',
        description:
            'Ces informations sont transmises au gestionnaire  en temps réel, pour simplifier les remplacements.',
        image: imgMobileAvailabilities,
        alt: 'Staffea, application mobile affichage des disponibilités',
    },
    {
        title: 'Echange de jours avec son équipe',
        description: 'Ces demandes de modification sont transmises au gestionnaire, toujours en temps réel.',
        image: imgMobileExchange,
        alt: 'Staffea, application mobile échange de jours entre collaborateurs',
    },
    {
        title: 'Notifications push immédiates',
        description: 'Après chaque changement de planning pour plus de réactivité.',
        image: imgMobileNotifications,
        alt: 'Staffea application mobile affichage notification push',
    },
]

export default content
