import React, { ReactElement, useEffect, useRef, useState } from 'react'

import { Box, Flex, Icon, Text, useMediaQuery } from '@chakra-ui/react'
import { CgBorderStyleDotted } from 'react-icons/cg'
import { v4 as uuidv4 } from 'uuid'

interface FlowProps {
    children: ReactElement[]
    flowColor?: string
    finalStepText?: string
    horizontalSpacing?: number
    verticalSpacing?: string
}

const Flow = ({
    children,
    flowColor = 'indigo.500',
    finalStepText = 'Vous êtes prêt !',
    horizontalSpacing = 30,
    verticalSpacing = '12',
}: FlowProps) => {
    const [isHorizontal] = useMediaQuery([`(min-width: 1200px)`])
    const [gridWidth, setGridWidth] = useState(0)

    const ref = useRef<HTMLDivElement>(null)

    const getColumnWidth = () => (gridWidth - (children.length - 1) * horizontalSpacing) / children.length

    const VERTICAL_POSITION = '-60px'
    const HORIZONTAL_POSITION = '100px'
    const VERTICAL_DOTS_POSITION = '40%'
    const HORIZONTAL_DOTS_POSITION = '-130px'

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            setGridWidth(entries[0].contentRect.width)
        })

        observer.observe(ref.current as Element)

        return () => {
            ref.current && observer.unobserve(ref.current)
        }
    }, [])

    return (
        <Box
            ref={ref}
            pt="16"
            pb="28"
            maxW={children.length === 2 ? '880px' : children.length === 3 ? '1330px' : '1750px'}
            mx="auto"
            display="grid"
            gridTemplateColumns={`repeat(${children.length}, 1fr)`}
            justifyItems="center"
            gap={`${horizontalSpacing}px`}
            sx={{
                '@media (max-width: 1200px)': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: verticalSpacing,
                },
            }}
        >
            {children.map((child, index) => {
                if (index === children.length - 1) {
                    return (
                        <Flex key={uuidv4()} position="relative" height="fit-content">
                            <Icon
                                as={CgBorderStyleDotted}
                                color={flowColor}
                                w="16"
                                h="16"
                                transform="rotate(-90deg)"
                                position="absolute"
                                bottom={VERTICAL_POSITION}
                                left={VERTICAL_DOTS_POSITION}
                            />

                            <Text
                                w="100%"
                                color={flowColor}
                                fontWeight="bold"
                                position="absolute"
                                bottom={`-${HORIZONTAL_POSITION}`}
                                align="center"
                            >
                                {finalStepText}
                            </Text>

                            <Flex
                                position="absolute"
                                top={isHorizontal ? HORIZONTAL_POSITION : VERTICAL_POSITION}
                                left={isHorizontal ? HORIZONTAL_DOTS_POSITION : VERTICAL_DOTS_POSITION}
                            >
                                {Array.from({ length: isHorizontal ? 2 : 1 }, () => (
                                    <Icon
                                        key={uuidv4()}
                                        as={CgBorderStyleDotted}
                                        color={flowColor}
                                        w="16"
                                        h="16"
                                        transform={!isHorizontal ? 'rotate(-90deg)' : 'none'}
                                    />
                                ))}
                            </Flex>

                            {React.cloneElement(child, {
                                zIndex: 2,
                                maxW: isHorizontal ? getColumnWidth() : 'none',
                            })}
                        </Flex>
                    )
                }

                if (index === 0) {
                    return (
                        <Flex key={uuidv4()} position="relative" height="fit-content">
                            <Icon
                                key={uuidv4()}
                                as={CgBorderStyleDotted}
                                color={flowColor}
                                w="16"
                                h="16"
                                transform="rotate(-90deg)"
                                position="absolute"
                                top={VERTICAL_POSITION}
                                left={VERTICAL_DOTS_POSITION}
                            />

                            {React.cloneElement(child, {
                                zIndex: 2,
                                maxW: isHorizontal ? getColumnWidth() : 'none',
                            })}
                        </Flex>
                    )
                }

                return (
                    <Flex key={index} position="relative" height="fit-content">
                        <Flex
                            position="absolute"
                            top={isHorizontal ? HORIZONTAL_POSITION : VERTICAL_POSITION}
                            left={isHorizontal ? HORIZONTAL_DOTS_POSITION : VERTICAL_DOTS_POSITION}
                        >
                            {Array.from({ length: isHorizontal ? 2 : 1 }, () => (
                                <Icon
                                    key={uuidv4()}
                                    as={CgBorderStyleDotted}
                                    color={flowColor}
                                    w="16"
                                    h="16"
                                    transform={!isHorizontal ? 'rotate(-90deg)' : 'none'}
                                />
                            ))}
                        </Flex>

                        {React.cloneElement(child, {
                            zIndex: 2,
                            maxW: isHorizontal ? getColumnWidth() : 'none',
                        })}
                    </Flex>
                )
            })}
        </Box>
    )
}

export default Flow
