/* eslint-disable react/jsx-newline */

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'

import imgDesktopPlanning from '@assets/images/desktop-planning.png'
import imgMobilePlanning from '@assets/images/mobile-planning.png'
import gradients from '@assets/svg/gradients-center.svg'
import { Box, Center, Flex, Heading, Icon, Image, Text, useMediaQuery } from '@chakra-ui/react'
import Seo from '@components/Seo'
import Section from '@components/core/Section'
import { CtaButton } from '@components/ui/Button'
import { Card, GradientCard, HorizontalGridCard } from '@components/ui/Card'
import { HorizontalCarousel, VerticalCarousel } from '@components/ui/Carousel'
import { MobileDesktopDisplay } from '@components/ui/Display'
import Flow from '@components/ui/Flow'
import IconItemList from '@components/ui/IconItemList'
import IconLink from '@components/ui/IconLink'
import Link from '@components/ui/Link'
import MenuSticky from '@components/ui/MenuSticky'
import Quote from '@components/ui/Quote'
import { features, functionalities, horizontalCarouselContent, verticalCarouselContent } from '@data/ehpad'
import { CALENDLY_URL } from '@helpers/url'
import { graphql } from 'gatsby'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { FaLongArrowAltRight } from 'react-icons/fa'
import { GrCheckmark } from 'react-icons/gr'
import { v4 as uuidv4 } from 'uuid'

const EhpadPage = () => {
    const [isTablet, isDesktop] = useMediaQuery(['(min-width: 1200px)', '(min-width: 1600px)'])

    const renderParagraph = (text: string, highlights: string[]) => {
        const result = []
        let lastIndex = 0
        for (const highlight of highlights) {
            const index = text.indexOf(highlight, lastIndex)

            if (index === -1) {
                continue
            }

            result.push(
                <Text key={index + 'base'} variant="inline" color="indigo.500" fontSize="xs">
                    {text.slice(lastIndex, index)}
                </Text>
            )

            result.push(
                <Text key={index + 'hightlist'} variant="bold" color="green.500" fontSize="xs">
                    {highlight}
                </Text>
            )

            lastIndex = index + highlight.length
        }
        result.push(
            <Text key="last" variant="inline" color="indigo.500" fontSize="xs">
                {text.slice(lastIndex)}
            </Text>
        )
        return <Box textAlign="start">{result}</Box>
    }

    return (
        <>
            <Seo
                title="Logiciel Planning EHPAD"
                description="Découvrez Staffea pour la gestion de vos plannings en EHPAD ➜ ✓ Installation rapide ✓ Création en 2H ✓ Gestion en temps réel ➜ Demandez une démo !"
            />

            <main>
                <MenuSticky>
                    <Section bg="indigo.100" id="ephad-staffea-section" name="staffea">
                        <Flex
                            pt={20}
                            pb={16}
                            gap={8}
                            align="center"
                            justify={['center', 'center', 'space-between']}
                            wrap="wrap"
                            maxW="1200px"
                            mx="auto"
                        >
                            <Flex
                                direction="column"
                                align="center"
                                mx="auto"
                                sx={{
                                    '@media (min-width: 1440px)': {
                                        maxW: '50%',
                                        marginX: 'revert',
                                        alignItems: 'start',
                                    },
                                }}
                            >
                                <Heading
                                    as="h1"
                                    fontWeight="bold"
                                    color="indigo.500"
                                    textAlign={['center', 'center', 'start']}
                                    mb={10}
                                >
                                    Logiciel de planning pour EHPAD
                                </Heading>

                                <IconItemList list={features} icon={GrCheckmark} align="start" />

                                <Flex direction="column" w={['100%', '100%', '50%']} pt="12">
                                    <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                        <CtaButton
                                            text="Demander une démo"
                                            description="Démonstration de 30 minutes en visioconférence"
                                        />
                                    </a>
                                </Flex>
                            </Flex>

                            <Box
                                mx="auto"
                                sx={{
                                    '@media (min-width: 1440px)': {
                                        marginX: 'revert',
                                    },
                                }}
                            >
                                <MobileDesktopDisplay
                                    mobileContent={{
                                        image: (
                                            <Image
                                                src={imgMobilePlanning}
                                                alt="Staffea, application mobile écran de consultation du planning individuel"
                                            />
                                        ),
                                        description: 'A chaque salarié, son planning individuel !',
                                    }}
                                    desktopContent={{
                                        image: (
                                            <Image
                                                src={imgDesktopPlanning}
                                                alt="Staffea, application web écran de gestion du planning des managers"
                                            />
                                        ),
                                        description: 'Votre interface de gestion des plannings.',
                                    }}
                                />
                            </Box>
                        </Flex>
                    </Section>

                    <Section bg="white" id="ehpad-interoperability-section" name="interopérabilité">
                        <Box py={20}>
                            <GradientCard maxW="800px">
                                <Flex direction="column" alignItems="center" justifyContent="center" color="white">
                                    <Text as="h2" fontSize="lg" fontWeight="bold" align="center" mb={6}>
                                        Avec Staffea, pas d&#39;installation informatique complexe à prévoir.
                                        <br /> Notre solution se connecte à tous les logiciels RH !
                                    </Text>

                                    <Text fontSize="sm" align="center" px={[0, 0, 20]} mb={8}>
                                        Grâce à notre solution 100% web, un simple accès internet suffit pour vous
                                        connecter à Staffea. Aucune installation compliquée n&#39;est requise ! Notre
                                        équipe technique se charge de vous former pour découvrir les fonctionnalités de
                                        Staffea et modifier facilement les plannings. Quant à l&#39;application mobile,
                                        vos collaborateurs n&#39;auront aucun mal à l&#39;utiliser ! <br /> <br />
                                        Staffea s&#39;interface avec les solutions déjà en place dans votre
                                        établissement : logiciels métiers et solutions RH. Vous n&#39;aurez pas besoin
                                        de modifier toute votre organisation pour bénéficier des avantages de notre
                                        plateforme !
                                    </Text>

                                    <IconLink
                                        text="Voir le process d'intégration"
                                        to={`#ehpad-onboarding-section`}
                                        icon={AiOutlineArrowDown}
                                        color="white"
                                        hoverColor="green.500"
                                    />
                                </Flex>
                            </GradientCard>
                        </Box>
                    </Section>

                    <Section bg="indigo.100" id="ephad-mobile-app-section" name="app mobile">
                        <Box py={20}>
                            <Center>
                                <Text as="h2" fontSize="lg" fontWeight="bold" color="indigo.500" mb={10}>
                                    Une application mobile dédiée aux collaborateurs
                                    <Text as="span" color="green.500" display="block">
                                        pour gagner en réactivité.
                                    </Text>
                                </Text>
                            </Center>

                            <VerticalCarousel
                                contents={verticalCarouselContent}
                                footer={
                                    <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                        <CtaButton
                                            height="14"
                                            text="Demander une démo"
                                            description="Démonstration de 30 minutes en visioconférence"
                                        />
                                    </a>
                                }
                            />
                        </Box>
                    </Section>

                    <Section bg="white" id="ehpad-features-section" name="fonctionnalités">
                        <Box py={20}>
                            <Text as="h2" fontSize="lg" fontWeight="bold" color="indigo.500" mb={10}>
                                Des fonctionnalités de planning précieuses
                                <Text as="span" color="green.500" display="block">
                                    pour économiser du temps.
                                </Text>
                            </Text>

                            <Flex my="12" gap="8" wrap="wrap" mx="auto">
                                {functionalities.map(({ id, media, title, content }) => (
                                    <HorizontalGridCard
                                        minH="170"
                                        key={id}
                                        media={media}
                                        title={title}
                                        content={content}
                                        maxW="485px"
                                        imagePadding={isDesktop ? '25' : '5'}
                                    />
                                ))}
                            </Flex>

                            <Text as="h2" fontSize="lg" fontWeight="bold" color="indigo.500" py={10}>
                                Les points forts de Staffea
                                <Text as="span" color="green.500" display="block">
                                    pour séréniser la gestion de planning en EHPAD.
                                </Text>
                            </Text>

                            <HorizontalCarousel
                                content={horizontalCarouselContent.map(({ title, description, image, alt }) => {
                                    return {
                                        key: title,
                                        title,
                                        description: renderParagraph(description.text, description.highlight),
                                        image: <Image key={uuidv4()} src={image} alt={alt} />,
                                    }
                                })}
                            />
                        </Box>
                    </Section>

                    <Section
                        id="ehpad-onboarding-section"
                        name="onboarding"
                        backgroundImage={gradients}
                        footer={
                            <Section bg="indigo.500">
                                <Flex
                                    w="fit-content"
                                    mx="auto"
                                    py="6"
                                    gap={6}
                                    align="center"
                                    direction={['column', 'column', 'row']}
                                >
                                    <Text as="span" variant="inline" color="white" fontSize="sm" align="center">
                                        Staffea réduit <Text variant="bold">drastiquement</Text> le temps de saisie et
                                        de modification des plannings de vos équipes.
                                    </Text>

                                    <Icon
                                        as={FaLongArrowAltRight}
                                        color="white"
                                        w="6"
                                        h="6"
                                        display={['none', 'none', 'block']}
                                    />

                                    <a href={CALENDLY_URL} target="_blank" rel="noreferrer">
                                        <CtaButton width="200px" hoverColor="green.500" text="Réserver une démo !" />
                                    </a>
                                </Flex>
                            </Section>
                        }
                    >
                        <Box py={20}>
                            <Text as="h2" fontSize="lg" fontWeight="bold" color="indigo.500" mb={4}>
                                Un accompagnement technique
                                <Text as="span" color="green.500" display="block">
                                    pour vous aider à déployer Staffea dans votre EHPAD.
                                </Text>
                            </Text>

                            <Flow>
                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Récupération de vos données
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Vous pouvez ajouter vos collaborateurs un par un sur Staffea, mais pour plus
                                        d&#39;efficacité il est possible de créer automatiquement leurs profils via un
                                        import ou une interface avec la gestion administrative des salariés, via notre
                                        API.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Configuration du planning
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        La configuration de vos plannings est facilitée grâce à la création de
                                        roulements types sur Staffea. Paramétrez différents roulements et intégrez-les
                                        pour vos collaborateurs. Le + : si votre roulement n&#39;est pas conforme,
                                        Staffea le détecte automatiquement et vous informe !
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Formation au logiciel
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Nous fournissons 2 jours de formation à vos plannings managers afin de leur
                                        apprendre à paramétrer le logiciel et à modifier les plannings en toute
                                        autonomie. Vous bénéficiez également d&#39;une aide en ligne et d&#39;un support
                                        utilisateur à tout instant.
                                    </Text>
                                </Card>

                                <Card size="xs" align="center">
                                    <Text
                                        as="h3"
                                        color="indigo.500"
                                        fontWeight="bold"
                                        mb="4"
                                        fontSize={isTablet && !isDesktop ? 'sm' : 'md'}
                                    >
                                        Mise en service
                                    </Text>

                                    <Text color="indigo.500" mb="4" fontSize={isTablet && !isDesktop ? 'xs' : 'md'}>
                                        Le déploiement de notre solution est rapide, puisqu&#39;aucune installation
                                        n&#39;est requise. Staffea est accessible avec un simple accès internet !
                                        L&#39;installation de votre nouveau logiciel de planning est garantie en moins
                                        de 48H pour commencer à l&#39;utiliser très rapidement.
                                    </Text>
                                </Card>
                            </Flow>
                        </Box>
                    </Section>

                    <Section
                        bg="indigo.100"
                        id="ehpad-mission-section"
                        name="mission"
                        footer={
                            <Section bg="white">
                                <Box py={20}>
                                    <Center textAlign="center">
                                        <Text as="h2" fontSize="lg" fontWeight="bold" color="indigo.500" mb={10}>
                                            Vous soignez vos résidents.
                                            <Text as="span" color="green.500" display="block">
                                                Nous soignons vos équipes !
                                            </Text>
                                        </Text>
                                    </Center>
                                    <Box mb="16">
                                        <Quote
                                            content="Une planification rigoureuse est indispensable pour améliorer la sérénité de vos collaborateurs et ainsi accroître la qualité des soins et la satisfaction des résidents.
                                            Mais nous savons qu'il est souvent difficile d'assurer la continuité des soins 24/7, entre les arrêts maladies et l'absentéisme en EHPAD. Vous y parvenez peut-être, mais dans l’urgence et sans être suffisamment attentif au bien-être de vos collaborateurs, internes comme externes. 
                                            Grâce au recours à l'intelligence artificielle et au machine learning de notre logiciel de planning pour la gestion du personnel d'EHPAD, nous vous offrons une solution intuitive et efficace : moins de temps passé à la recherche d'un remplaçant, moins de formulaires papier, des tâches automatisées... Plus de sérénité pour vous et vos équipes sur le terrain !"
                                            author="Mathieu MOSQUET - Cofondateur de Staffea"
                                            nbCol={2}
                                        />
                                    </Box>
                                    <Center>
                                        <Card size="lg" p="10" hasShadow={true}>
                                            <Text color="indigo.500" align="center">
                                                Découvrez comment Staffea&nbsp;
                                                <Link
                                                    variant="underlineBold"
                                                    to="/ehpad"
                                                    _hover={{ color: 'green.500' }}
                                                >
                                                    améliore la qualité de vie au travail en EHPAD
                                                </Link>
                                                &nbsp;en offrant plus de réactivité et de flexibilité à chacun.
                                            </Text>
                                        </Card>
                                    </Center>
                                </Box>
                            </Section>
                        }
                    >
                        <Box py={20}>
                            <Center textAlign="center">
                                <Text as="h2" fontSize="lg" fontWeight="bold" color="indigo.500" mb={10}>
                                    Une solution adaptée
                                    <Text as="span" color="green.500" display="block">
                                        à toutes les professions en EHPAD.
                                    </Text>
                                </Text>
                            </Center>

                            <Text color="indigo.500" mb={16}>
                                Optimisez la qualité de vie au travail de vos médecins, infirmiers, aide-soignants et
                                ASH grâce au planning pour EHPAD Staffea. Le logiciel idéal pour planifier facilement
                                les horaires de chaque profession ! Vous êtes à la recherche d&#39;un&nbsp;
                                <Link
                                    to="/ehpad/lp/planning-infirmier"
                                    variant="underlineBold"
                                    _hover={{ color: 'green.500' }}
                                >
                                    planning pour vos infirmiers en EHPAD&nbsp;
                                </Link>
                                ? D&#39;un&nbsp;
                                <Link
                                    to="/ehpad/lp/planning-aide-soignant"
                                    variant="underlineBold"
                                    _hover={{ color: 'green.500' }}
                                >
                                    planning pour vos aide-soignants&nbsp;
                                </Link>
                                ? D&#39;un&nbsp;
                                <Link
                                    to="/ehpad/lp/planning-ash"
                                    variant="underlineBold"
                                    _hover={{ color: 'green.500' }}
                                >
                                    planning pour vos ASH&nbsp;
                                </Link>
                                ? Staffea vous offre tout ça dans une seule solution, sans oublier vos plannings de nuit
                                ! &nbsp;Plus de sérénité pour vous et pour vos équipes.
                            </Text>
                        </Box>
                    </Section>
                </MenuSticky>
            </main>
        </>
    )
}

export default EhpadPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["ehpad", "404"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
